import React from "react";
import foundryBanner from "../asset/img/foundry/foundry.png";
import "./About.css";
import { GiAutoRepair, GiRobotGolem } from "react-icons/gi";
import { BsGear, BsGearFill, BsShop } from "react-icons/bs";
import whiteCastIronImg from "../asset/img/foundry/WhiteCastIron.png";
import grayCastIronImg from "../asset/img/foundry/GrayCastIron.png";

const Foundry = () => {
  return (
    <div className="container d-flex flex-column align-items-center">
      <div className="my-2">
        <img src={foundryBanner} alt="foundry_banner" />
      </div>
      <div>
        <br />
        <p className="text-center quote">
          Welcome to JIW Foundry Works, where we specialize in producing
          high-quality gray and white cast iron castings. Our expertise
          encompasses every stage of the process, from metal melting and molding
          to precise finishing. We are dedicated to delivering top-quality
          results that meet your exact specifications, ensuring durability and
          excellence in every casting we produce.
        </p>
      </div>
      <div className="row Feautes d-flex justify-content-center">
        <div className="col-lg-4 col-12">
          <div className="single-features">
            <div className="signle-icon">
              <div className="d-flex align-items-center justify-content-center">
                <BsGear />
              </div>
            </div>
            <h3>White Cast Iron</h3>
            <p>
              White cast iron is known for its hardness and wear resistance.
              We produce castings that are ideal for applications requiring
              high durability and strength.
            </p>
            <img src={whiteCastIronImg} alt="white_cast_iron" />
          </div>
        </div>
        <div className="col-lg-4 col-12">
          <div className="single-features last">
            <div className="signle-icon">
              <div className="d-flex align-items-center justify-content-center">
                <BsGearFill />
              </div>
            </div>
            <h3>Gray Cast Iron</h3>
            <p>
              Gray cast iron is recognized for its excellent machinability,
              vibration damping, and thermal conductivity. We specialize in
              producing high-quality gray cast iron castings used in a wide
              range of applications.
            </p>
            <img src={grayCastIronImg} alt="gray_cast_iron" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Foundry;
