import React from "react";
import "./WhatsAppBtn.css";
import { RiWhatsappLine } from "react-icons/ri";

export const WhatsAppBtn = () => {
  return (
    <div
      className="floating-whatsapp"
      style={{
        scale: "1",
      }}
    >
      <div className="d-flex align-item-center floating-icon">
        <a href="https://wa.me/94711528478" target="_blank">
          <RiWhatsappLine
          size={50}
            style={{
              // fontSize: "40px",
              color: "#25d366",
              // backgroundColor: "#25d366",
              // borderRadius: "50%",
              // padding: "10px",
              // boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
              // transition: "0.3s"
            }}
          />
        </a>
      </div>
    </div>
  );
};
