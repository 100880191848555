export const products = [
  {
    key: 1,
    label: "No 12 Spice Grinding Machine",
    popular:true,
    imagename: "12_spice_grinding_machine.png",
    category: "0.1",
    title: "No 12 Spice Grinding Machine",
    description:
      "The 12-inch spice grinding plate grinder is a robust and versatile machine designed for efficiently grinding spices and grains to desired textures. Whether used in commercial settings or at home, this grinder offers considerable capacity and power for handling various grinding tasks with ease. Suitable for both commercial establishments, such as spice shops, food processing facilities, and domestic kitchens where larger quantities of spices and grains need to be ground efficiently.",
    instruction: [
      "Grinding Machine No. 12 comes in two variations: a Brass bearing model and a Steel ball bearing model.",
      "The heavy-duty white CI casting plate crushes dry spices, chilies, by colliding with them, ensuring efficient grinding.",
      "The recommended motor for installation should have a minimum of 10 horsepower, operate on three-phase power, and maintain a normal speed of 1440 revolutions per minute (RPM).",
      "The motor pulley, railings, and belts necessary for the machine are provided along with the equipment.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/ Spice Powder ",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "35-40 kg/hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "10/15 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Three Phase",
      },
    ],
  },
  {
    key: 2,
    label: "No 12 Flour Grinding Machine",
    popular:true,
    imagename: "12_flour_grinding_machine.png",
    category: "0.2",
    title: "No 12 Flour Grinding Machine",
    description:
      "The No. 12 Flour Plate Grinding Machine is a robust piece of equipment designed for efficiently grinding flour. It features a sturdy construction and is equipped with specialised grinding plates optimised for flour production. This machine is engineered to operate with precision and reliability, providing consistent results in flour grinding tasks. No. 12 Flour Plate Grinding Machine is suitable for both commercial and industrial applications, meeting the demands of flour milling operations effectively.",
    instruction: [
      "Grinding Machine No. 12 comes in two variations: a Brass bearing model and a Steel ball bearing model.",
      "The heavy-duty white CI casting plate crushes dry rice flour by colliding with them, ensuring efficient grinding.",
      "The recommended motor for installation should have a minimum of 15 horsepower, operate on three-phase power, and maintain a normal speed of 1440 revolutions per minute (RPM).",
      "The motor pulley, railings, and belts necessary for the machine are provided along with the equipment.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Flour Powder",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "35-40 kg/hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "10/15 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Three Phase",
      },
    ],
  },
  {
    key: 3,
    label: "No 10 Spice Grinding Machine",
    popular:true,
    imagename: "10_spice_grinding_machine.png",
    category: "0.1",
    title: "No 10 Spice Grinding Machine",
    description:
      "The No. 10 Spice Grinding Plate Machine is a specialised apparatus designed specifically for grinding various spices in industrial settings. Engineered with durability and efficiency in mind, this machine is equipped with a heavy-duty grinding plate tailored to handle the rigorous demands of spice processing.The No. 10 Spice Grinding Plate Machine is specifically engineered for the efficient grinding of a variety of dry spices, including chilli, turmeric, and other spices commonly used in culinary applications.",
    instruction: [
      "Grinding Machine No. 10 comes in two variations: a Brass bearing model and a Steel ball bearing model.",
      "The heavy-duty white CI casting plate crushes dry spices, chilies, by colliding with them, ensuring efficient grinding.",
      "The recommended motor for installation should have a minimum of 5 horsepower, operate on single-phase or three-phase power, and maintain a normal speed of 1440 revolutions per minute (RPM).",
      "The motor pulley, railings, and belts necessary for the machine are provided along with the equipment.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/ Spice Powder ",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "20-25 kg/hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "5/7 HP(Three Phase)",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 4,
    label: "No 07 Spice Grinding Machine",
    popular:true,
    imagename: "7_spice_grinding_machine.png",
    category: "0.1",
    title: "No 07 Spice Grinding Machine",
    description:
      "The No. 07 Spice Grinding Machine is the perfect fit for small businesses aiming to step into spice processing. Compact and efficient, it grinds various spices swiftly and with ease, offering versatility to cater to different culinary needs. With its straightforward operation and durable construction, this machine ensures reliable performance while saving valuable space in your workspace.",
    instruction: [
      "Grinding Machine No. 7 is specifically designed as a Brass bearing model, ensuring smooth and reliable operation.",
      "The heavy-duty white CI casting plate crushes dry spices, chilies, by colliding with them, ensuring efficient grinding.",
      "The recommended motor for installation should have a minimum of 3 horsepower, operate on single-phase or three-phase power, and maintain a normal speed of 1440 revolutions per minute (RPM).",
      "The motor pulley, railings, and belts necessary for the machine are provided along with the equipment.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/Spice Powder ",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "85 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "3/5 HP(Three Phase)",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 5,
    label: "Super Shot Grinding Machine",
    popular:true,
    imagename: "super_shot_grinding_machine.png",
    category: "0.1",
    title: "Super Shot Grinding  Machine",
    description:
      "The Super Shot Grinding Machine is indeed a versatile milling machine designed to crush a variety of materials including dry maize, beans, spices, and more. Its high-speed operation and powerful performance make it a game-changer in spice processing, allowing it to grind through a kilo of chilies in just two and a half minutes. This groundbreaking machine represents a leap forward in efficiency and productivity, catering to the needs of spice manufacturers and culinary professionals seeking to streamline their production processes. With its lightning-fast performance and ability to handle a wide range of materials, the Super Shot Grinding Machine is at the forefront of innovation in the milling industry.",
    instruction: [
      "The Super Shot Grinding Machine offers flexible setup options, allowing you to install it without the need for nailing it into the ground or placing it on a rubber carpet. This versatility ensures convenient deployment in various environments.",
      "The machine comes equipped with six screens of different sizes, along with a screen frame. This feature provides versatility in grinding, allowing you to achieve different levels of fineness to suit your specific spice processing needs.",

      "Featuring four hardness cutters, this machine consistently produces finely ground powder.",

      "The Super Shot Grinding Machine comes with a motor pulley and belt, essential components for powering the machine's grinding mechanism.",

      "A spice collection bag is included with the machine, facilitating easy collection and storage of ground spices. This convenient accessory helps maintain cleanliness and organisation during the grinding process.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/Spice Powder ",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "20/25 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "5 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "2880 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 6,
    label: "Super Shot Double Grinding Machine",
    popular:true,
    imagename: "super_shot_double_grinding_machine.png",
    category: "0.1",
    title: "Super Shot Double Grinding Machine",
    description:
      "The Super Shot Double Machine is an advanced version of super shot machine. It features a double-stage mechanism for grinding grains and spices efficiently. Its heavy-duty CI casting grinding chamber and sturdy MS body structure ensure durability. The double-stage grinding reduces screen maintenance. Equipped with four plus two hardness cutters, it produces fine powder consistently. Conveniently, it can be installed on a rubber carpet, eliminating the need for a foundation. Additionally, six sets of screens are included for added versatility.",
    instruction: [
      "You can set up the Super Shot Double Machine on a rubber carpet, eliminating the need for a foundation. This feature offers flexibility in installation, allowing for easy deployment without the hassle of additional groundwork.",

      "The Super Shot Double Machine is equipped with a motor pulley and belt, which are vital for driving the grinding mechanism of the machine.",

      "Equipped with four plus two hardness cutters, it produces fine powder.",

      "With the machine, you'll find a spice collection bag, making it effortless to gather and store ground spices. This convenient accessory ensures tidy and organised spice storage after grinding.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/Spice Powder ",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "... kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "5 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "2880 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 7,
    label: "No.7/15 Multi Purpose Grinding Machine",
    imagename: "no_7_15_multi_purpose_grinding_machine.png",
    category: "0.1",
    title: "No.7/15 Multi Purpose Grinding Machine",
    description:
      "The No.7/15 Multi-Purpose Grinding Machine is a groundbreaking solution designed for small business owners, integrating the functions of both the No. 7 grinding machine and the FFC 15 Disk Mill machine into a single unit. Powered by a single motor, it efficiently grinds spices, chilli, flour, and more with dual functionality, allowing simultaneous processing to boost productivity. Its compact design and efficient performance make it an ideal choice for small businesses seeking an effective and efficient solution for their food processing tasks.",
    instruction: [
      "The recommended motor for installation should have a minimum of 3 horsepower, operate on single-phase or three-phase power, and maintain a normal speed of 1440 revolutions per minute (RPM).",
      "Motor pulley and belts are included with the machine.",
      "The No.7/15 Multi-Purpose Grinding Machine is designed to be fixed securely onto a table, ensuring stability and ease of use during operation. This setup enhances user convenience and safety, allowing for efficient grinding without the need for complicated adjustments or repositioning.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/Spice Powder ",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "8/20 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "3 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 8,
    label: "No.7/23 Multi Purpose Grinding Machine",
    imagename: "no_7_23_multi_purpose_grinding_machine.png",
    category: "0.1",
    title: "No.7/23 Multi Purpose Grinding Machine",
    description:
      "The No.7/23. Multi-Purpose Grinding Machine is a powerful solution for business owners, seamlessly combining the capabilities of the No. 7 grinding machine and the FFC 23 Disk Mill machine into one unit. With a single motor powering both components simultaneously, this machine efficiently grinds spices, chilli, flour, and more. Its dual functionality allows for simultaneous processing, making it highly effective for businesses handling large volumes of chilli, spices, and flour-related food processing tasks. By streamlining operations, this machine offers business owners an efficient and effective way to meet their production needs.",
    instruction: [
      "The recommended motor for installation should have a minimum of 5 horsepower, operate on single-phase or three-phase power, and maintain a normal speed of 2880 revolutions per minute (RPM).",
      "Motor pulley and belts are included with the machine.",
      "The No.7/23 Multi-Purpose Grinding Machine is designed to be fixed securely onto a table, ensuring stability and ease of use during operation. This setup enhances user convenience and safety, allowing for efficient grinding without the need for complicated adjustments or repositioning.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/Spice Powder ",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "15/50 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "5 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "2880 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 9,
    label: "Maldive Dry Fish Cutter Machine",
    imagename: "maldive_dry_fish_cutter_machine.png",
    category: "2",
    title: "Maldive Dry Fish Cutter Machine",
    description:
      "The Maldives Dry Fish Cutter is expertly crafted for the precise cutting and processing of dry fish. Featuring a heavy iron screen and four sturdy cutter blades, handcrafted for durability, this tool effortlessly transforms robust Maldives dry fish into finely cut diamond-shaped pieces.",
    instruction: [
      "The machine head, equipped with a steel hopper, is designed to open for convenient cleaning and maintenance, ensuring hassle-free upkeep.",

      "Handcrafted a solid iron screen and four resilient cutter blades, ensuring durability and robustness.",

      "The sturdy stand of this machine makes it easy to locate and position securely, enhancing stability during use.",

      "The machine comes complete with motor pulleys and belts.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Cutting dry maldive fish",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "60/100 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "5 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 10,
    label: "FFC 15 Disk Mill Machine",
    popular:true,
    imagename: "ffc_15_disk_mill_machine.png",
    category: "0.2",
    title: "FFC 15 Disk Mill Machine",
    description:
      "The Disk Mill No. 15 is a machine used in food processing to grind grains and spices into powders. It has rotating discs with serrated edges that crush and grind materials. This model is efficient for pulverising grains like wheat, corn, and rice, as well as spices such as pepper, cumin, and coriander.",
    instruction: [""],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Chili/Spice powder",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "10/15 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "2/3 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "2880 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 11,
    label: "FFC 23 Disk Mill Machine",
    popular:true,
    imagename: "ffc_23_disk_mill_machine.png",
    category: "0.2",
    title: "FFC 23 Disk Mill Machine",
    description:
      "The FFC 23 Disk Mill Machine is a compact yet powerful tool widely utilised in food processing. Its rotating discs with serrated edges efficiently grind grains and spices, ensuring consistent and fine results. With its versatility, it handles various materials like wheat, corn, rice, and spices such as pepper and cumin. Its sturdy construction and simple operation make it suitable for both small-scale and industrial applications, while easy maintenance enhances its practicality. Whether in milling facilities or food production units, the FFC 23 Disk Mill Machine excels in delivering finely ground ingredients essential for culinary and manufacturing needs.",
    instruction: [""],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Flour/Spice powder",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "35/45 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "5 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "2880 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase/Three Phase",
      },
    ],
  },
  {
    key: 12,
    label: "FFC 37 Disk Mill Machine",
    popular:true,
    imagename: "ffc_37_disk_mill_machine.png",
    category: "0.2",
    title: "FFC 37 Disk Mill Machine",
    description:
      "The FFC 37 Disk Mill Machine is a compact and efficient tool designed for grinding grains and spices in food processing applications. Featuring rotating discs with serrated edges, it effectively pulverises various materials, ensuring consistent and finely ground output. With its versatility, it can process a wide range of grains like wheat, corn, and rice, as well as spices such as pepper and cumin. Its robust construction and user-friendly design make it suitable for industrial operations. Easy maintenance adds to its appeal, making the FFC 37 Disk Mill Machine a reliable choice for achieving desired texture and fineness in food production processes.",
    instruction: [""],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Flour/Spice powder",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "120/150 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "10 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Three Phase",
      },
    ],
  },
  {
    key: 13,
    label: "FFC 45 Disk Mill Machine",
    popular:true,
    imagename: "ffc_45_disk_mill_machine.png",
    category: "0.2",
    title: "FFC 45 Disk Mill Machine",
    description:
      "The FFC 45 Disk Mill Machine is a compact and reliable grinding tool widely used in food processing industries. Its rotating discs with serrated edges efficiently crush and grind various grains and spices, ensuring consistent and finely ground output. With its versatility, it can handle a diverse range of materials, including wheat, corn, rice, and spices like pepper and cumin. Its sturdy construction and user-friendly design make it suitable for industrial operations. Easy maintenance further enhances its practicality, making the FFC 45 Disk Mill Machine an indispensable asset for achieving desired texture and fineness in food production processes.",
    instruction: [""],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Flour/Spice powder",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "175/200 kg hr",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "15 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Three Phase",
      },
    ],
  },
  {
    key: 14,
    label: "Pulveriser Machine",
    popular:true,
    imagename: "ffc_45_disk_mill_machine.png",
    category: "0.2",
    title: "Pulveriser Machine",
    description:
      "The double stage pulverizer machine is designed to grind, crush, or pulverise various materials into smaller particles or powders. It's suitable for all types of grains and spices. The machine features a heavy-duty cast iron grinding chamber and a robust mild steel body structure, ensuring durability and reliability. With its double stage grinding process, there's reduced maintenance of the screen, and the six plus four hardness cutter delivers finely ground flour. Operating at 3000 to 4000 rpm, it generates noise compared to traditional stone-type flour mills. Additionally, six sets of screens are included with this machine for added versatility.",
    instruction: [
      "Setting up the Pulverizer Machine requires installing the necessary foundation to ensure stability and proper operation.",
      "Its six plus four hardness cutters efficiently produce fine powder from various materials.",
      "Additionally, the machine includes a spice collection bag, simplifying the gathering and storage of ground spices. This accessory promotes tidy and organised spice storage post-grinding, enhancing convenience for users.",
    ],
    specifications: [
      {
        key: 0,
        label: "Usage",
        description: "Processing Flour/Spice powder",
      },
      {
        key: 1,
        label: "Production Capacity",
        description: "250-450 kg hr(Flour)/50-75 kg hr(Spice)",
      },
      {
        key: 2,
        label: "Motor Capacity",
        description: "10/15 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Three Phase",
      },
    ],
  },
  {
    key: 15,
    label: "Cassava slicer machine ",
    imagename: "cassava_slice_machine.png",
    category: "2",
    title: "Cassava slicer machine ",
    description:
      "The cassava slicer machine is a specialised tool designed to efficiently slice cassava into thin and uniform pieces, simplifying the process of preparing cassava for cooking or processing.",
    instruction: [
      "Setting up the Pulverizer Machine requires installing the necessary foundation to ensure stability and proper operation.",
      "Its six plus four hardness cutters efficiently produce fine powder from various materials.",
      "Additionally, the machine includes a spice collection bag, simplifying the gathering and storage of ground spices. This accessory promotes tidy and organised spice storage post-grinding, enhancing convenience for users.",
    ],
    specifications: [
      {
        key: 2,
        label: "Motor Capacity",
        description: "1 HP",
      },
      {
        key: 4,
        label: "Motor Speed",
        description: "1440 RPM",
      },
      {
        key: 5,
        label: "Power Supply",
        description: "Single Phase",
      },
    ],
  },
  
];
