import React from "react";
import { MessengerBtn } from "./MessengerBtn";
import { WhatsAppBtn } from "./WhatsAppBtn";
import "./floating-dock.css";

export const FloatingDock = () => {
  return (
    <div className="floating-dock">
      <div className="h-100 d-flex justify-content-center align-items-center">
        <WhatsAppBtn />
        <MessengerBtn />
      </div>
    </div>
  );
};
