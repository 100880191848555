import React, { useEffect } from "react";
import { useState } from "react";

export const Thumbnail = ({ topic, imagename }) => {
  const containerStyle = {
    position: "relative",
    textAlign: "center",
  };

  const imageStyle = {
    width: "100%",
  };

  const textStyle = {
    position: "absolute",
    bottom: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    fontSize: "1em",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Black with 70% transparency
    padding: "5px", // Optional: add padding to the text
    borderRadius: "5px", // Optional: add border radius to the text background
    width: "100%",
  };

  const [image, setImage] = useState();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../asset/img/items/${imagename}`);
        setImage(response.default);
      } catch (err) {
        console.log(err);
      } finally {
        // console.log("Image Loaded");
      }
    };
    fetchImage();
  }, [imagename]);

  return (
    <div style={containerStyle}>
      <div className="d-flex justify-content-center" style={{height:"250px", backgroundColor:"#001532"}}>
        <img
          src={image}
          className="img-fluid"
          alt=""
          style={{
            borderTopRightRadius: "25px", // Adjust the value as needed
            height: "100%",
            width: "auto"

          }}
        />
      </div>

      <h1 style={textStyle}>{topic}</h1>
    </div>
  );
};
