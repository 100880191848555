import React from "react";
import "./services.css";

const Services = () => {
  return (
    <section className="services section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
              <h2>We Offer Different Services To Improve Your Business</h2>

              <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit praesent
                aliquet. pretiumts
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12  ">
            {/* <div className=" hoverservice"> */}
              <div className=" single-service">
              <i className="fa-solid fa-shop"></i>
              <h4>
                <h5>Sales of Machines</h5>
              </h4>
              <p>
                Offer a comprehensive catalog of machines available for
                purchase.{" "}
              </p>
              {/* </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-solid fa-gears"></i>
              <h4>
                <h5>Customization Services</h5>
              </h4>
              <p>
                Provide options for customization based on specific client
                requirements.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-brands fa-instalod"></i>
              <h4>
                <h5>Installation Services</h5>
              </h4>
              <p>
                Offer professional installation services for the purchased
                machines.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-solid fa-user-doctor"></i>
              <h4>
                <h5>Training Programs</h5>
              </h4>
              <p>
                Conduct training sessions for clients' staff on the proper
                operation and maintenance of the machines.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-solid fa-screwdriver-wrench"></i>
              <h4>
                <h5>Maintenance and Repairs</h5>
              </h4>
              <p>
                Offer routine maintenance services to ensure machines operate at
                peak efficiency.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-solid fa-life-ring"></i>
              <h4>
                <h5>Spare Parts Supply</h5>
              </h4>
              <p>
                aintain an inventory of spare parts for quick replacements.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-solid fa-headset"></i>
              <h4>
                <h5>Consultation Services</h5>
              </h4>
              <p>
                Provide expert advice on the best machines for specific
                applications.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-solid fa-upload"></i>
              <h4>
                <h5>Upgrades and Retrofitting</h5>
              </h4>
              <p>
                Keep clients informed about upgrades available for their
                machines.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-service">
              <i className="fa-solid fa-globe"></i>
              <h4>
                <h5>Online Support</h5>
              </h4>
              <p>
                Provide online support for troubleshooting and technical
                assistance.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
          <div className="single-service">
            <i className="fa-solid fa-file-lines"></i>
            <h4>
              <h5>Documentation Services</h5>
            </h4>
            <p>
              Supply comprehensive documentation for each machine, including
              manuals and technical specifications.{" "}
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="single-service">
            <i className="fa-solid fa-circle-info"></i>
            <h4>
              <h5>After-Sales Support</h5>
            </h4>
            <p>
              Implement a robust after-sales support system to address any
              post-purchase concerns.{" "}
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12">
          <div className="single-service">
            <i className="fa-solid fa-tv"></i>
            <h4>
              <h5>Remote Monitoring Services</h5>
            </h4>
            <p>
              Implement remote monitoring solutions for machines to detect
              issues early.{" "}
            </p>
          </div>
        </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
