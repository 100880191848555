import { Link } from "react-router-dom";
import React from "react";
import img from "../asset/img/logo1.png";
import "./footer.css";
import { IoCall } from "react-icons/io5";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
const Footer = () => {
  return (
    <footer id="footer" className="footer overflow-hidden">
      <div className="watermark">
        <img src={img} alt="logo"/>
      </div>
      <div className="watermark2">
        <img src={img} alt="logo"/>
      </div>
      <div className="footer-top">
        <div className="container">
          <div className="row" style={{ zIndex: "999" }}>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-footer">
                <h2>About Us</h2>
                <p>
                Jayantha Engineering Works, founded in 1987 in Malabe, Sri Lanka, specializes in agricultural and industrial equipment, entrepreneur tools, foundry equipment parts, and iron casting.
                </p>

                {/* <ul className="social">
                  <li>
                    <Link to="https://www.facebook.com/profile.php?id=61550880461195&mibextid=ZbWKwL">
                      <i className="icofont-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-google-plus"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-vimeo"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="icofont-pinterest"></i>
                    </Link>
                  </li>
                </ul> */}
                {/* <img
                  src="/img/logo1.png"
                  alt="logo"
                  style={{width:"50%"}}
                /> */}
              </div>
            </div>
            <div className="col-lg-2 col-md-5 col-12">
              <div className="single-footer f-link">
                <h2>Quick Links</h2>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <ul style={{ paddingLeft: "0rem" }}>
                      <li>
                        <Link to="/">
                          <i
                            className="fa-solid fa-hand-point-right"
                            // <i class="fa-solid fa-hand-point-right"></i>
                            aria-hidden="true"
                          ></i>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">
                          <i
                            className="fa-solid fa-hand-point-right"
                            aria-hidden="true"
                          ></i>
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/category">
                          <i
                            className="fa-solid fa-hand-point-right"
                            aria-hidden="true"
                          ></i>
                          Categories
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact">
                          <i
                            className="fa-solid fa-hand-point-right"
                            aria-hidden="true"
                          ></i>
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-7 col-12">
              <div className="single-footer f-link">
                <h2>Contact Via</h2>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <ul style={{ paddingLeft: "0rem" }}>
                      <li>
                        <Link>
                          <IoCall /> &nbsp; +94 112 412 680
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <MdOutlinePhoneAndroid /> &nbsp; +94 711 528 478
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <IoIosMail /> &nbsp;
                          jayanthaengineeringworks@gmail.com
                        </Link>
                      </li>
                      <li>
                        <Link>
                          <FaLocationDot /> &nbsp; 2/C, Kahanthota road, Malabe, Sri Lanka.
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-footer">
                <h2>Open Hours</h2>
                <p className="text-justify">
                  Our company maintains regular business hours throughout the
                  week, providing you with consistent access to our services.
                </p>
                <ul className="time-sidual" style={{ paddingLeft: "0rem" }}>
                  <li className="day">
                    Monday - Friday <span>7.30-16.30</span>
                  </li>
                  <li className="day">
                    Saturday <span>7.30-15.30</span>
                  </li>
                  <li className="day">
                    Sunday <span>Closed</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright" style={{ backgroundColor: "#9093CC" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="copyright-content ">
                <p className="text-black ">
                  © Copyright 2024 | Designed & Developed by{" "}
                  <Link
                    to="https://buildzoneit.com"
                    target="_blank"
                    className="text-black fw-bold"
                  >
                    BuildZone IT Solutions
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
